<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :buscar="false" :eliminar="false"></nav-form>
    <section>
      <article>
        <field
          widget="image"
          name="imagen"
          style="
            position: absolute;
            width: 50px;
            height: 50px;
            top: 5px;
            left: 5px;
          "
        />
        <field
          name="nombre_comercial"
          widget="input"
          label="Nombre comecial"
          placeholder="Nombre Comercial"
          width="260px"
          style="top: 9px; left: 80px"
          inputStyle="text-align:left; font-weight:bold;"
          required
          help="Nombre comercial"
          searchable
          @input="onInputNombreComercial"
          @focus="onFocusNombreComercial"
        />
        <r-tabs
          ref="main-tabs"
          style="top: 55px; height: 278px"
          :buttons="['General', 'Bancos']"
        >
          <div class="tab">
            <field
              name="nombre_fiscal"
              label="Nombre fiscal"
              inline-label
              placeholder="Nombre Fiscal"
              width="260px"
              style="top: 13px; right: 275px"
              @blur="onBlurNombreFiscal"
            />
            <field
              name="nif_cif"
              widget="input"
              label="NIF/CIF"
              inline-label
              placeholder="NIF/CIF"
              width="260px"
              style="top: 43px; right: 275px"
              @change="onChangeNifCif"
              :class="{
                wrong:
                  formData.nif_cif &&
                  !$utils.validation.checkVATNumber(formData.nif_cif),
              }"
            />
            <field
              name="direccion"
              widget="input"
              label="Direccion"
              inline-label
              placeholder="Direccion fiscal"
              width="260px"
              style="top: 73px; right: 275px"
            />
            <field
              name="direccion1"
              widget="input"
              label
              inline-label
              placeholder="Direccion fiscal"
              width="260px"
              style="top: 103px; right: 275px"
            />
            <field
              name="cp_fiscal"
              max="6"
              widget="input"
              label="C.P."
              inline-label
              placeholder="CP"
              searchable
              inputalign="center"
              width="60px"
              style="top: 133px; right: 475px"
              @change="onChangeCP('fiscal', false)"
            />
            <field
              name="poblacion_fiscal"
              widget="input"
              label
              inline-label
              placeholder="Poblacion"
              width="190px"
              style="top: 133px; right: 275px"
            />
            <field
              name="provincia_fiscal_id"
              widget="m2o"
              dbAdapter="provincia"
              label="Provincia"
              inline-label
              placeholder="Provincia"
              searchable
              width="260px"
              style="top: 163px; right: 275px"
            />
            <field
              name="pais_fiscal_id"
              widget="m2o"
              dbAdapter="pais"
              label="Pais"
              inline-label
              searchable
              width="260px"
              style="top: 193px; right: 275px"
            />
            <field
              name="telefono"
              widget="int"
              label="Telefono"
              inline-label
              placeholder="Telefono"
              width="180px"
              style="top: 13px; right: 30px"
            />
            <field
              name="telefono1"
              widget="int"
              label="Telefono 1"
              inline-label
              placeholder="Telefono 1"
              width="180px"
              style="top: 43px; right: 30px"
            />
            <field
              name="movil"
              widget="int"
              label="Movil"
              inline-label
              placeholder="Movil"
              width="180px"
              style="top: 73px; right: 30px"
            />
            <field
              name="fax"
              widget="int"
              label="Fax"
              inline-label
              placeholder="Fax"
              width="180px"
              style="top: 103px; right: 30px"
            />
            <field
              name="email"
              widget="input"
              label="E-Mail"
              inline-label
              placeholder="e-mail"
              width="180px"
              style="top: 133px; right: 30px"
              :class="{
                wrong:
                  formData.email &&
                  !$utils.validation.checkEmail(formData.email),
              }"
            />
            <field
              name="web"
              widget="input"
              label="Web"
              inline-label
              placeholder="Web"
              width="180px"
              style="top: 163px; right: 30px"
            />
            <field
                name="recargo_equivalencia"
                widget="checkbox"
                label="Recargo de equivalencia en compras"
                inline-label
                style="top: 193px; right: 70px"
              />
              <span style="position: absolute; top: 195px; right: 30px"
                >{{ parseFloat(app.config.recargo_equivalencia || 0).toFixed(1) }}%</span
              >
          </div>
          <div class="tab">
            <field
              name="bancos"
              widget="handsontable"
              style="top: 5px; left: 5px"
              :columns="[
                {
                  name: 'entidad',
                  header: 'Entidad',
                  type: 'm2o',
                  primary: 'codigo',
                  image: 'imagen',
                  fields: ['precio_coste', 'swift'],
                  label: 'nombre',
                },
                {
                  name: 'entidad',
                  type: 'm2o',
                  header: 'SWIFT',
                  primary: 'codigo',
                  label: 'swift',
                  readOnly: true,
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'cuenta',
                  header: 'Cuenta',
                  renderer: rendererCuenta,
                  allowInvalid: true,
                },

                { name: 'cif_emisor', header: 'CIF emisor' },
                { name: 'id_banco_emisor', header: 'ID banco emisor' },
              ]"
              :htSettings="htSettingsBancosEmpresa"
            />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import CliProvRepMixin from "./../components/CliProvRepMixin.js";
export default {
  mixins: [WindowMixin, rFormMixin, CliProvRepMixin],
  data: function () {
    var self = this;
    return {
      title: "Empresa",
      dbAdapter: "empresa",
      itemId: 1,
      atajo_anadir: false,
      htSettingsBancosEmpresa: {
        beforeChange: function (changes, source) {
          var h = this;
          for (var i = changes.length - 1; i >= 0; i--) {
            var change = changes[i];
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];
            if (prop == "cuenta") {
              newVal = newVal || "";
              newVal = newVal.replace(/-|\s/g, ""); //Eliminamos espacios y guiones
              if (newVal.length == 20) {
                var value =
                  self.$utils.validation.calculateIBAN(newVal, "ES") + newVal;
              } else if (newVal.length == 24) {
                value = newVal;
              }
              if (newVal.length == 20 || newVal.length == 24) {
                var iban = value.substr(0, 4);
                var entidad = value.substr(4, 4);
                var sucursal = value.substr(8, 4);
                var dc = value.substr(12, 2);
                var nCuenta = value.substr(14, 10);
                changes[i][3] =
                  iban +
                  " " +
                  entidad +
                  " " +
                  sucursal +
                  " " +
                  dc +
                  " " +
                  nCuenta;
              }
            }
          }
        },
      },
    };
  },
  mounted() {
    var self = this;
    self.$on("save", function (data) {
      self.app.empresa = data;
    });
  },
  methods: {},
};
</script>
