import Handsontable from "handsontable";
export default {
    data: function () {
        var self = this;
        return {
            copyNombreFiscal: true,
            nif_cif_duplicado: false,
            htSettingsBancos: {
                beforeChange: function (changes, source) {
                    var h = this;
                    for (var i = changes.length - 1; i >= 0; i--) {
                        var change = changes[i];
                        var row = change[0];
                        var prop = change[1];
                        var oldVal = change[2];
                        var newVal = change[3];
                        if (prop == "cuenta") {
                            newVal = newVal || "";
                            newVal = newVal.replace(/-|\s/g, ""); //Eliminamos espacios y guiones
                            if (newVal.length == 20) {
                                var value =
                                    self.$utils.validation.calculateIBAN(newVal, "ES") + newVal;
                            } else if (newVal.length == 24) {
                                value = newVal;
                            }
                            if (newVal.length == 20 || newVal.length == 24) {
                                var iban = value.substr(0, 4);
                                var entidad = value.substr(4, 4);
                                var sucursal = value.substr(8, 4);
                                var dc = value.substr(12, 2);
                                var nCuenta = value.substr(14, 10);
                                changes[i][3] =
                                    iban +
                                    " " +
                                    entidad +
                                    " " +
                                    sucursal +
                                    " " +
                                    dc +
                                    " " +
                                    nCuenta;
                            }
                        }
                        if (prop == "predet" && source != "auto") {
                            newVal = parseInt(newVal) || 0;
                            if (newVal) {
                                window.$.each(h.getDataAtProp("predet"), function (ind, item) {
                                    if (!h.isEmptyRow(ind))
                                        h.setDataAtRowProp(ind, "predet", 0, "auto");
                                });
                            } else {
                                changes[i][3] = 1;
                            }
                        }
                        if (prop != "predet" && source != "modId") {
                            if (!h.getDataAtProp("predet").some(v => parseInt(v) || 0)) {
                                h.setDataAtRowProp(row, "predet", 1, "auto");
                            }
                        }
                    }
                }
            },
            rendererCuenta: function (
                instance,
                td,
                row,
                col,
                prop,
                value,
                cellProperties
            ) {
                Handsontable.renderers.BaseRenderer.apply(this, arguments);
                value = value || "";
                value = value.replace(/-|\s/g, ""); //Eliminamos espacios y guiones
                if (!value) {
                    var error = false;
                } else if (value.length == 24) {
                    var entidad = value.substr(4, 4);
                    var sucursal = value.substr(8, 4);
                    var dc = value.substr(12, 2);
                    var nCuenta = value.substr(14, 10);
                    if (self.$utils.validation.checkCCC(entidad, sucursal, dc, nCuenta)) {
                        if (
                            self.$utils.validation.calculateIBAN(
                                entidad + sucursal + dc + nCuenta,
                                "ES"
                            ) == value.substr(0, 4)
                        ) {
                            error = false;
                        } else {
                            error = true;
                        }
                    } else {
                        error = true;
                    }
                } else if (value.length == 20) {
                    entidad = value.substr(0, 4);
                    sucursal = value.substr(4, 4);
                    dc = value.substr(8, 2);
                    nCuenta = value.substr(10, 10);
                    if (self.$utils.validation.checkCCC(entidad, sucursal, dc, nCuenta)) {
                        error = false;
                    } else {
                        error = true;
                    }
                } else {
                    error = true;
                }
                if (error) td.classList.add("wrong");
                //Handsontable.renderers.TextRenderer.apply(this, arguments);
                td.textContent = value;
                return td;
            },

        }
    },
    methods: {
        onChangeCP: function (cual, copyComercial = true) {
            /*var self = this;
            var cp = self.formData["cp_" + cual];
            if (!cp) return;
            if (cual == "fiscal" && copyComercial) self.onChangeCopy("cp_fiscal", "cp_comercial");
            window.DB.getList("provincia", {
                filter: ["codigo", "LIKE", cp.substring(0, 2)],
                depth: 2,
                fields: ["nombre", { name: "pais_id", fields: ["nombre", 'iva', 're'] }]
            }).then(function(res) {
                if (parseInt(res.count)) {
                    self.$set(self.formData, "provincia_" + cual + "_id", res.data[0]);
                    self.$set(self.formData, "pais_" + cual + "_id", res.data[0].pais_id);
                    self.$emit("change", "provincia_" + cual + "_id", "auto");
                    self.$emit("change", "pais_" + cual + "_id", "auto");
                    if (cual == "fiscal" && copyComercial) {
                        cual = "comercial";
                        self.$set(self.formData, "provincia_" + cual + "_id", res.data[0]);
                        self.$set(
                            self.formData,
                            "pais_" + cual + "_id",
                            res.data[0].pais_id
                        );
                        self.$emit("change", "provincia_" + cual + "_id", "auto");
                        self.$emit("change", "pais_" + cual + "_id", "auto");
                    }
                } else {
                    self.$set(self.formData, "provincia_" + cual + "_id", {});
                    self.$set(self.formData, "pais_" + cual + "_id", {});
                    self.$emit("change", "provincia_" + cual + "_id", "auto");
                    self.$emit("change", "pais_" + cual + "_id", "auto");
                    if (cual == "fiscal" && copyComercial) {
                        cual = "comercial";
                        self.$set(self.formData, "provincia_" + cual + "_id", {});
                        self.$set(self.formData, "pais_" + cual + "_id", {});
                        self.$emit("change", "provincia_" + cual + "_id", "auto");
                        self.$emit("change", "pais_" + cual + "_id", "auto");
                    }
                }
            });*/
        },
        onChangeNifCif: function (e, source) {
            var self = this;
            if (!self.formData.nif_cif ||
                self.mode == "search" ||
                self.mode == "empty" ||
                self.mode == "view"
            )
                return;
            let conditions = ["nif_cif", "=", self.formData.nif_cif];
            if (self.itemId) conditions.push(["codigo", "!=", self.itemId]);
            window.DB.getList(self.dbAdapter, {
                filter: conditions,
                fields: [self.primary]
            }).then(function (res) {
                if (parseInt(res.count)) {
                    self.nif_cif_duplicado = true;
                } else {
                    self.nif_cif_duplicado = false;
                }
            });
        },
        onFocusNombreComercial() {
            var self = this;
            if (self.formData.nombre_fiscal) self.copyNombreFiscal = false;
            else self.copyNombreFiscal = true;
        },
        onInputNombreComercial($event) {
            var self = this;
            if (self.copyNombreFiscal && self.mode != "search") {
                self.$set(self.formData, "nombre_fiscal", $event.target.value);
            }
        },
        onBlurNombreFiscal($event) {
            var self = this;
            if (self.formData.nombre_fiscal) self.copyNombreFiscal = false;
            else self.copyNombreFiscal = true;
        },
        onChangeCopy(orig, dest) {
            if (this.mode == "search" || this.formData[dest]) return;
            this.$set(this.formData, dest, this.formData[orig]);
            this.$emit("change", dest, "auto");
        }
    },
    mounted() {
        var self = this;
        self.$on("change", function (prop, source) {
            if (self.mode != "new" && self.mode != "edit") return;
            // Copiar IVA y RE
            if (prop == 'pais_fiscal_id') {
                let pais = self.formData.pais_fiscal_id;
                if (!pais) return;
                //self.$set(self.formData, 'iva', pais.iva);
                self.$set(self.formData, 'impuesto_id', pais.impuesto_id);
                self.$set(self.formData, 'nacionalidad', pais.nacionalidad);
                if (self.dbAdapter != 'representante') {
                    //self.$set(self.formData, 're', pais.re);
                    self.$set(self.formData, 'divisa_id', pais.moneda_id);
                }
            }
            // País automático
            if (prop == 'provincia_fiscal_id') {
                self.$set(self.formData, 'pais_fiscal_id', self.formData.provincia_fiscal_id.pais_id);
                self.$emit("change", "pais_fiscal_id", "auto");
            }
            if (prop == 'provincia_comercial_id') {
                self.$set(self.formData, 'pais_comercial_id', self.formData.provincia_comercial_id.pais_id);
                self.$emit("change", "pais_comercial_id", "auto");
            }
        });
    }
};